"use client";

import { JSX, useState, useEffect, useRef } from "react";
import Image from "next/image";
import { StaticImageData } from "next/image";
import { useTranslations } from "next-intl";
import { motion } from "framer-motion";
import { Play, Pause, Plus, Minus, Star } from "lucide-react";
import config from "@/config";

// Reference type definitions
interface RefType {
  id: string;
}

const refTypes: Record<string, RefType> = {
  creator1: { id: "creator1" },
  creator2: { id: "creator2" },
  creator3: { id: "creator3" },
  creator4: { id: "creator4" },
  creator5: { id: "creator5" },
  creator6: { id: "creator6" }
};

// Types
interface TestimonialStats {
  subscribers?: string;
  monthlyViews?: string;
  averageWatch?: string;
  followers?: string;
  engagement?: string;
  growth?: string;
  clientGrowth?: string;
  productivity?: string;
  roi?: string;
}

interface Testimonial {
  name: string;
  role: string;
  text: string;
  type: RefType;
  img?: string;
}

// Updated testimonials with more specific success stories
const list: Testimonial[] = [
  {
    name: "Sarah Chen",
    role: "Educational Content Creator",
    text: "AriaFlow's AI script generator and stock footage integration helped me create my first educational video series.",
    type: refTypes.creator1,
    img: "/testimonials/sarah.jpg"
  },
  {
    name: "田中 美咲",
    role: "コンテンツクリエイター",
    text: "AriaFlowのAIスクリプト生成機能と素材ライブラリを使って、教育コンテンツの制作が驚くほど簡単になりました。",
    type: refTypes.creator2
  },
  {
    name: "김지원",
    role: "크리에이티브 디렉터",
    text: "AriaFlow의 AI 기술 덕분에 콘텐츠 제작 시간이 크게 단축되었습니다.",
    type: refTypes.creator3
  },
  {
    name: "王小明",
    role: "数字内容创作者",
    text: "AriaFlow的AI辅助功能让视频制作变得轻而易举。智能模板和自动化工具帮助我更专注于创意内容的构思。",
    type: refTypes.creator4
  },
  {
    name: "Mike Rodriguez",
    role: "Finance Content Creator",
    text: "The AI helps me break down complex financial topics into engaging short-form videos. AriaFlow's templates save me hours of work!",
    type: refTypes.creator5
  },
  {
    name: "Hans Mueller",
    role: "Digitaler Content-Ersteller",
    text: "AriaFlow hat meine Content-Erstellung komplett verändert. Die KI-gestützten Tools ermöglichen es mir, professionelle Videos zu erstellen.",
    type: refTypes.creator6
  }
];

const StatCard = ({ label, value }: { label: string; value: string }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="flex flex-col items-center p-6 rounded-2xl bg-gradient-to-br from-base-200/80 to-base-100/40 backdrop-blur-xl border border-white/10 hover:border-primary/20 transition-all duration-300 hover:shadow-lg hover:shadow-primary/5 group"
  >
    <span className="text-4xl font-bold bg-gradient-to-r from-primary via-secondary to-accent bg-clip-text text-transparent group-hover:scale-105 transition-transform duration-300">
      {value}
    </span>
    <span className="text-sm font-medium text-base-content/70 mt-2">{label}</span>
  </motion.div>
);

const TestimonialCard = ({ testimonial, featured = false }: { testimonial: Testimonial; featured?: boolean }) => {
  const cardClass = featured
    ? "col-span-1 sm:col-span-2 lg:col-span-1 p-8 bg-gradient-to-br from-primary/5 via-secondary/5 to-transparent"
    : "p-6 bg-gradient-to-br from-base-200/60 to-base-100/30";

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      className={`
        rounded-2xl border border-white/10 hover:border-primary/20 
        backdrop-blur-xl shadow-xl hover:shadow-2xl 
        transition-all duration-500 hover:-translate-y-1
        ${cardClass}
      `}
    >
      <div className="flex flex-col h-full">
        <div className="mb-6">
          <div className="flex gap-1.5">
            {[1, 2, 3, 4, 5].map((star) => (
              <Star key={star} className="w-5 h-5 text-primary" fill="currentColor" />
            ))}
          </div>
        </div>
        
        <blockquote className="flex-grow">
          <p className={`
            ${featured ? 'text-lg' : 'text-base'} 
            text-base-content/90 leading-relaxed
            before:content-['"'] after:content-['"']
            before:text-4xl after:text-4xl
            before:text-primary/30 after:text-primary/30
            before:mr-1 after:ml-1
          `}>
            {testimonial.text}
          </p>
        </blockquote>

        <div className="flex items-center justify-between mt-8 pt-6 border-t border-base-content/5">
          <div className="flex items-center gap-4">
            <div className="relative">
              {testimonial.img ? (
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-br from-primary/20 to-secondary/20 rounded-full animate-pulse" />
                  <Image
                    src={testimonial.img}
                    alt={testimonial.name}
                    width={48}
                    height={48}
                    className="rounded-full object-cover ring-2 ring-white/10"
                  />
                </div>
              ) : (
                <div className="w-12 h-12 rounded-full bg-gradient-to-br from-primary/20 to-secondary/20 flex items-center justify-center ring-2 ring-white/10">
                  <span className="text-lg font-semibold text-primary">
                    {testimonial.name[0]}
                  </span>
                </div>
              )}
              {featured && (
                <div className="absolute -top-1 -right-1 bg-gradient-to-r from-primary to-secondary rounded-full p-1.5 ring-2 ring-white/10">
                  <Star className="w-3 h-3 text-white" fill="white" />
                </div>
              )}
            </div>
            <div>
              <div className="font-semibold text-base-content">{testimonial.name}</div>
              <div className="text-sm text-base-content/70">{testimonial.role}</div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const TestimonialsAriaFlow11 = () => {
  const t = useTranslations('Testimonials');

  return (
    <section className="relative py-24 overflow-hidden">
      {/* 优化背景效果 */}
      <div className="absolute inset-0 bg-gradient-to-b from-base-200 via-base-100 to-base-200">
        <div className="absolute inset-0 bg-[url('/grid.svg')] opacity-20" />
        <div className="absolute inset-0 bg-gradient-to-br from-primary/5 via-transparent to-secondary/5" />
      </div>
      
      {/* 装饰性元素 */}
      <div className="absolute top-0 left-0 w-96 h-96 bg-primary/10 rounded-full filter blur-3xl animate-pulse" />
      <div className="absolute bottom-0 right-0 w-96 h-96 bg-secondary/10 rounded-full filter blur-3xl animate-pulse" />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-20">
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block px-4 py-1.5 mb-6 text-sm font-medium rounded-full 
              bg-gradient-to-r from-primary/10 to-secondary/10 
              border border-primary/20 text-primary"
          >
            {t('subtitle')}
          </motion.span>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl sm:text-5xl font-extrabold mb-6 bg-gradient-to-r from-primary via-secondary to-accent bg-clip-text text-transparent"
          >
            {t('title')}
          </motion.h2>

          {/* Stats Section */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 max-w-3xl mx-auto mt-16">
            <StatCard label={t('statsLabels.creators')} value={t('stats.creators')} />
            <StatCard label={t('statsLabels.videos')} value={t('stats.videos')} />
            <StatCard label={t('statsLabels.views')} value={t('stats.views')} />
          </div>
        </div>

        {/* Testimonials Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8">
          {list.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              testimonial={testimonial}
              featured={index === 0}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsAriaFlow11;
