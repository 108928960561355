'use client';

import React, { useRef, useEffect, useState } from 'react';
import { Heart, MessageCircle, Share2, Bookmark, Music } from 'lucide-react';
import { motion } from 'framer-motion';
import { Button } from "@/components/ui/button";
import { useTranslations } from 'next-intl';

const VideoShowcase = ({ locale }: { locale?: string }) => {
  const t = useTranslations('VideoShowcase');
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loadedVideos, setLoadedVideos] = useState<Set<number>>(new Set());

  const handleVideoLoaded = (index: number) => {
    setLoadedVideos(prev => new Set(prev).add(index));
  };

  useEffect(() => {
    // 静音所有视频并自动播放
    videoRefs.current.forEach((video, index) => {
      if (video) {
        video.muted = true;
        video.volume = 0;
        
        // 如果视频已经加载过，标记为已加载
        if (video.readyState >= 3) {
          handleVideoLoaded(index);
        }
        
        if (index === activeIndex) {
          video.play().catch(error => console.error('播放失败:', error));
        }
      }
    });
  }, [activeIndex]);

  // 从翻译中获取视频类别数据
  const videoCategories = [
    {
      type: 'historical',
      title: t('categories.historical.title'),
      url: `https://media.ariaflow.ai/ariaflow/video/7bab13dd-fffc-4095-a745-23cfde5d1f09.mp4`,
      likes: '3.1M',
      comments: '45.4K',
      shares: '233.8K',
      username: t('categories.historical.username'),
      caption: t('categories.historical.caption'),
      bgColor: 'from-indigo-500/20 to-purple-500/20'
    },
    {
      type: 'gaming',
      title: t('categories.gaming.title'),
      url: `https://media.ariaflow.ai/ariaflow/video/63e1410e-1db2-4011-9947-73c7044d3470.mp4`,
      likes: '2.7M',
      comments: '48.6K',
      shares: '183.9K',
      username: t('categories.gaming.username'),
      caption: t('categories.gaming.caption'),
      bgColor: 'from-green-500/20 to-emerald-500/20'
    },
    {
      type: 'dialogue',
      title: 'Interesting Facts',
      url: 'https://media.ariaflow.ai/ariaflow/video/e12275bd-59d7-4c7a-b67a-a3e3a02000db.mp4',
      likes: '2.9M',
      comments: '65.3K',
      shares: '233.8K',
      username: '@facts.daily',
      caption: 'Did you know this about ancient cultures? #facts #history',
      bgColor: 'from-blue-500/20 to-sky-500/20'
    },
    {
      type: 'vintage',
      title: 'Vintage Stories',
      url: 'https://media.ariaflow.ai/ariaflow/video/e997c85e-1e05-4bfb-9cfe-2ff83afd648a.mp4',
      likes: '3.1M',
      comments: '46.2K',
      shares: '193.9K',
      username: '@vintage.clips',
      caption: 'Memories from a different era #vintage #classic',
      bgColor: 'from-amber-500/20 to-orange-500/20'
    }
  ];

  return (
    <section className="relative py-28 overflow-hidden bg-black">
      {/* 高级背景效果 */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-950 via-gray-900 to-black"></div>
      <div className="absolute inset-0 opacity-30" 
        style={{ 
          backgroundImage: 'radial-gradient(circle at 25px 25px, rgba(255, 255, 255, 0.2) 2px, transparent 0), radial-gradient(circle at 75px 75px, rgba(255, 255, 255, 0.2) 2px, transparent 0)',
          backgroundSize: '100px 100px' 
        }}>
      </div>
      
      {/* 装饰球体 */}
      <div className="absolute top-1/4 -left-32 w-96 h-96 rounded-full bg-gradient-to-r from-primary/30 to-secondary/10 blur-3xl"></div>
      <div className="absolute bottom-1/4 -right-32 w-96 h-96 rounded-full bg-gradient-to-r from-secondary/20 to-primary/10 blur-3xl"></div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* 标题部分 - 优化文案 */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7 }}
          className="text-center mb-12 space-y-4"
        >
          {/* <span className="inline-block px-4 py-1.5 bg-gradient-to-r from-primary/20 to-secondary/20 text-white rounded-full text-sm font-medium">
            {t('poweredByAI')}
          </span> */}
          
          <h2 className="text-4xl md:text-5xl font-extrabold text-white leading-tight">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary via-secondary to-accent">
              {t('viral')}
            </span>{' '}
            {t('faceless')}{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-accent via-secondary to-primary">
              {t('videos')}
            </span>
          </h2>
          
          <p className="text-lg text-gray-400 max-w-2xl mx-auto">
            {t('description')}
          </p>
        </motion.div>
        
        {/* 手机展示部分 */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-6">
          {videoCategories.map((category, index) => (
            <motion.div 
              key={index}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              onMouseEnter={() => setActiveIndex(index)}
              className="transform transition-all duration-500 hover:scale-105 group"
            >
              <div className={`relative mx-auto w-full max-w-[310px] ${index === activeIndex ? 'z-10' : 'z-0'}`}>
                {/* 手机衬托背景 */}
                <div className={`absolute -inset-4 rounded-[2.5rem] bg-gradient-to-br ${category.bgColor} opacity-0 group-hover:opacity-100 transition-opacity duration-700 blur-lg`}></div>
                
                {/* 手机框架 - 优化以匹配 1080 × 1920 视频 */}
                <div className="relative rounded-[1.8rem] border-[5px] border-gray-800 bg-black shadow-[0_0_15px_5px_rgba(0,0,0,0.3)] overflow-hidden flex flex-col">
                  {/* 添加微妙的屏幕反光效果 */}
                  <div className="absolute inset-0 bg-gradient-to-br from-white/5 to-transparent pointer-events-none z-20"></div>
                  
                  {/* 添加边框高光效果 */}
                  <div className="absolute inset-0 rounded-[1.5rem] border border-white/10 pointer-events-none"></div>
                  
                  {/* 状态栏 - 保持在视频区域之外 */}
                  <div className="relative h-6 z-10 flex items-center justify-between px-5 pt-1 bg-gradient-to-b from-black/60 to-black/0 backdrop-blur-sm">
                    <div className="text-white text-[10px] font-medium">09:47</div>
                    <div className="flex space-x-1.5">
                      <svg className="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M12.01 21.49L23.64 7c-.45-.34-4.93-4-11.64-4C5.28 3 .81 6.66.36 7l11.63 14.49.01.01.01-.01z" />
                      </svg>
                      <svg className="w-3 h-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33V8h10V5.33z" /><path d="M7 8v12.67C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V8H7z" />
                      </svg>
                    </div>
                  </div>
                  
                  {/* 视频区域 - 精确匹配 9:16 比例 */}
                  <div className="relative aspect-[9/16] bg-black group">
                    <video
                      ref={el => { videoRefs.current[index] = el }}
                      src={category.url}
                      className="absolute inset-0 w-full h-full object-cover transform transition-transform duration-700 group-hover:scale-[1.02]"
                      loop
                      muted
                      playsInline
                      autoPlay={index === activeIndex}
                      preload="auto"
                    />
                    
                    {/* 视频悬停效果 */}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10 pointer-events-none"></div>
                    
                    {/* 社交媒体UI元素叠加 */}
                    <div className="absolute inset-0 flex flex-col justify-between p-4">
                      {/* 顶部导航条 */}
                      <div className="flex justify-between text-white text-xs">
                        <div className="flex items-center space-x-3">
                          <span className="opacity-70">{t('following')}</span>
                          <span className="font-bold border-b-2 border-white pb-0.5">{t('forYou')}</span>
                        </div>
                        <div>
                          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M15.5 14h-.79l-.28-.27a6.5 6.5 0 0 0 1.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505 6.505 0 0 0-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 0 0 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                          </svg>
                        </div>
                      </div>
                      
                      {/* 底部用户信息和交互区 */}
                      <div className="flex justify-between items-end">
                        {/* 用户信息 */}
                        <div className="text-white">
                          <div className="font-semibold text-sm">{category.username}</div>
                          <div className="text-xs mt-1 max-w-[160px] opacity-80">{category.caption}</div>
                          <div className="flex items-center mt-2 text-xs">
                            <Music className="w-3 h-3 mr-1" />
                            <span className="truncate w-[120px]">{t('originalSound')}</span>
                          </div>
                        </div>
                        
                        {/* 互动按钮 */}
                        <div className="flex flex-col items-center gap-4">
                          <button className="group flex flex-col items-center">
                            <div className="bg-black/30 p-2 rounded-full transform group-hover:scale-110 transition-transform duration-200">
                              <Heart className="w-5 h-5 text-white group-hover:text-red-500 transition-colors" fill="transparent" />
                            </div>
                            <span className="text-white text-xs mt-1">{category.likes}</span>
                          </button>
                          
                          <button className="group flex flex-col items-center">
                            <div className="bg-black/30 p-2 rounded-full transform group-hover:scale-110 transition-transform duration-200">
                              <MessageCircle className="w-5 h-5 text-white" />
                            </div>
                            <span className="text-white text-xs mt-1">{category.comments}</span>
                          </button>
                          
                          <button className="group flex flex-col items-center">
                            <div className="bg-black/30 p-2 rounded-full transform group-hover:scale-110 transition-transform duration-200">
                              <Share2 className="w-5 h-5 text-white" />
                            </div>
                            <span className="text-white text-xs mt-1">{category.shares}</span>
                          </button>
                          
                          <button className="group">
                            <div className="relative w-10 h-10 rounded-lg bg-black/50 flex items-center justify-center overflow-hidden transform group-hover:scale-110 transition-transform duration-200">
                              <div className="w-6 h-6 rounded-full bg-white animate-spin-slow"></div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* 类别标签 - 优化 */}
              <div className="mt-6 text-center">
                <span className={`inline-block px-4 py-1.5 bg-gradient-to-r ${category.bgColor} text-white rounded-full text-sm font-medium backdrop-blur-sm transform transition-all duration-300 hover:scale-105 hover:shadow-lg hover:shadow-${category.type}-500/20 cursor-pointer`}>
                  {category.title}
                </span>
              </div>
            </motion.div>
          ))}
        </div>
        
        {/* 行动召唤 */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="mt-20 text-center"
        >
          <a href="/dashboard" className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-primary to-secondary text-white font-medium rounded-xl transition-all duration-300 hover:scale-105 hover:shadow-lg hover:shadow-primary/20 group">
            <span>{t('startCreating')}</span>
            <svg className="ml-2 w-5 h-5 transition-transform duration-300 group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </a>
        </motion.div>

        {/* 标准视频播放器
        <div className="w-full max-w-4xl mx-auto mt-16">
          <h3 className="text-2xl font-bold text-white mb-6 text-center">{title}</h3>
          {description && <p className="text-gray-400 text-center mb-8">{description}</p>}
          
          <div className="aspect-video bg-black rounded-lg overflow-hidden">
            <video
              src={videoUrl}
              poster={posterUrl}
              controls
              className="w-full h-full object-contain"
            />
          </div>
          <div className="mt-4 flex justify-end">
            <Button variant="outline" size="sm">
              下载视频
            </Button>
            <Button className="ml-2" size="sm">
              分享
            </Button>
          </div>
        </div> */}
      </div>
    </section>
  );
};

// 添加到 CSS 中的自定义动画
// .animate-spin-slow {
//   animation: spin 4s linear infinite;
// }

export default VideoShowcase; 