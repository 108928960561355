"use client";

import { motion } from "framer-motion";
import { LightningIcon, BrainIcon, TemplateIcon, EditIcon, DevicesIcon } from "./icons/BenefitIcons";
import { useInView } from "react-intersection-observer";
import React from "react";

interface BenefitItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  delay: number;
}

const BenefitItem = ({ icon, title, description, delay }: BenefitItemProps) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay: delay * 0.1 }}
      className="flex flex-col items-center p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow border border-gray-100"
    >
      <div className="mb-4 p-3 bg-primary/10 rounded-full text-primary">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2 text-gray-900">{title}</h3>
      <p className="text-gray-600 text-center">{description}</p>
    </motion.div>
  );
};

export default function Benefits() {
  const benefits = [
    {
      icon: <LightningIcon className="w-7 h-7" />,
      title: "Lightning-Fast Creation",
      description: "Generate professional-quality videos in seconds, not hours.",
    },
    {
      icon: <BrainIcon className="w-7 h-7" />,
      title: "AI-Powered Assistance",
      description: "From script writing to visual styling, our AI has got you covered.",
    },
    {
      icon: <TemplateIcon className="w-7 h-7" />,
      title: "Vast Template Library",
      description: "Access thousands of trending templates for any niche or platform.",
    },
    {
      icon: <EditIcon className="w-7 h-7" />,
      title: "Smart Editing Tools",
      description: "Effortlessly add captions, voiceovers, and effects with our intuitive interface.",
    },
    {
      icon: <DevicesIcon className="w-7 h-7" />,
      title: "Multi-Platform Optimization",
      description: "Ensure your content shines on TikTok, Instagram, YouTube Shorts, and more.",
    },
  ];

  return (
    <section id="benefits" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="text-primary font-medium text-sm uppercase tracking-wider"
          >
            WHY CHOOSE US
          </motion.span>
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="text-3xl sm:text-4xl font-bold mt-2 text-gray-900"
          >
            Transform Your Content Strategy
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto"
          >
            AriaFlow.ai delivers everything you need to create stunning videos that captivate your audience.
          </motion.p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <BenefitItem
              key={index}
              icon={benefit.icon}
              title={benefit.title}
              description={benefit.description}
              delay={index + 3}
            />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
          className="mt-12 text-center"
        >
          <a
            href="#pricing"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-primary/90 transition-colors"
          >
            Get Started Today
          </a>
        </motion.div>
      </div>
    </section>
  );
} 