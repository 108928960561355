interface IconProps {
  className?: string;
}

export const LightningIcon = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 10V3L4 14H11V21L20 10H13Z" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const BrainIcon = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4.5C10.5995 4.5 9.49485 5.37698 9.13219 6.61432C9.05059 6.91436 8.77769 7.11429 8.46642 7.11429C8.0768 7.11429 7.76071 6.78571 7.76071 6.38571C7.76071 4.51914 9.63197 3 12 3C14.3681 3 16.2393 4.51914 16.2393 6.38571C16.2393 6.78571 15.9232 7.11429 15.5336 7.11429C15.2223 7.11429 14.9494 6.91436 14.8678 6.61432C14.5052 5.37698 13.4005 4.5 12 4.5Z" fill="currentColor"/>
    <path d="M15.75 9C16.9926 9 18 7.99264 18 6.75C18 5.50736 16.9926 4.5 15.75 4.5C14.5074 4.5 13.5 5.50736 13.5 6.75C13.5 7.99264 14.5074 9 15.75 9Z" fill="currentColor"/>
    <path d="M8.25 9C9.49264 9 10.5 7.99264 10.5 6.75C10.5 5.50736 9.49264 4.5 8.25 4.5C7.00736 4.5 6 5.50736 6 6.75C6 7.99264 7.00736 9 8.25 9Z" fill="currentColor"/>
    <path d="M17.9951 10.5H18C19.6569 10.5 21 11.8431 21 13.5C21 14.7337 20.2037 15.7965 19.0995 16.242C19.6598 16.7183 20 17.4179 20 18.1875C20 19.7384 18.6798 21 17.05 21H14.1C13.4641 21 12.8521 20.8567 12.2999 20.598C11.7476 20.8567 11.1359 21 10.5 21H6.95C5.32022 21 4 19.7384 4 18.1875C4 17.4179 4.34021 16.7183 4.90047 16.242C3.79633 15.7965 3 14.7337 3 13.5C3 11.8431 4.34315 10.5 6 10.5H6.00492C6.5731 10.5 7.10665 10.6488 7.56177 10.9146C7.8102 10.6372 8.13058 10.425 8.49168 10.2945C8.17506 9.94861 8 9.51083 8 9.03371C8 7.91043 8.89543 7 10 7C10.4351 7 10.8367 7.14317 11.16 7.38121C11.4833 7.14317 11.8849 7 12.3 7C13.4046 7 14.3 7.91043 14.3 9.03371C14.3 9.51083 14.1249 9.94861 13.8083 10.2945C14.1694 10.425 14.4898 10.6372 14.7382 10.9146C15.1934 10.6488 15.7269 10.5 16.2951 10.5H17.9951Z" fill="currentColor"/>
  </svg>
);

export const TemplateIcon = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="18" height="18" rx="2" stroke="currentColor" strokeWidth="2"/>
    <path d="M3 9H21" stroke="currentColor" strokeWidth="2"/>
    <path d="M9 21L9 9" stroke="currentColor" strokeWidth="2"/>
  </svg>
);

export const EditIcon = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 20H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.5 3.5C16.8978 3.10217 17.4374 2.87868 18 2.87868C18.2786 2.87868 18.5544 2.93355 18.8118 3.04015C19.0692 3.14676 19.303 3.30301 19.5 3.5C19.697 3.69698 19.8532 3.93083 19.9598 4.18821C20.0665 4.4456 20.1213 4.72143 20.1213 5C20.1213 5.27857 20.0665 5.5544 19.9598 5.81179C19.8532 6.06918 19.697 6.30302 19.5 6.5L7 19L3 20L4 16L16.5 3.5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export const DevicesIcon = ({ className }: IconProps) => (
  <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 16H3C2.46957 16 1.96086 15.7893 1.58579 15.4142C1.21071 15.0391 1 14.5304 1 14V6C1 5.46957 1.21071 4.96086 1.58579 4.58579C1.96086 4.21071 2.46957 4 3 4H17C17.5304 4 18.0391 4.21071 18.4142 4.58579C18.7893 4.96086 19 5.46957 19 6V8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 20H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13 16V20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16 12H22C22.5523 12 23 12.4477 23 13V19C23 19.5523 22.5523 20 22 20H16C15.4477 20 15 19.5523 15 19V13C15 12.4477 15.4477 12 16 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
); 